const ErrorBot = () => (
  <svg
    width="186"
    height="221"
    viewBox="0 0 186 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M111.899 107.636L134.093 106.075L111.899 104.55V107.636Z"
      fill="white"
    />
    <path
      d="M129.875 106.075C129.875 107.281 130.876 108.275 132.091 108.275C133.306 108.275 134.307 107.281 134.307 106.075C134.307 104.869 133.306 103.876 132.091 103.876C130.876 103.876 129.875 104.869 129.875 106.075Z"
      fill="#F16D78"
    />
    <path
      d="M114.401 112.141H115.045C118.154 112.141 120.691 109.622 120.691 106.536V105.259C120.691 102.173 118.154 99.6549 115.045 99.6549H114.401V112.141Z"
      fill="#101820"
    />
    <path
      d="M45.7456 107.636L23.5518 106.075L45.7456 104.55V107.636Z"
      fill="#101820"
    />
    <path
      d="M27.7685 106.075C27.7685 107.281 26.7678 108.275 25.5527 108.275C24.3376 108.275 23.3369 107.281 23.3369 106.075C23.3369 104.869 24.3376 103.876 25.5527 103.876C26.7678 103.876 27.7685 104.869 27.7685 106.075Z"
      fill="#F16D78"
    />
    <path
      d="M43.2432 112.141H42.5999C39.4906 112.141 36.9531 109.623 36.9531 106.537V105.26C36.9531 102.174 39.4906 99.655 42.5999 99.655H43.2432V112.141Z"
      fill="#101820"
    />
    <path
      d="M111.899 107.636L134.093 106.075L111.899 104.55V107.636Z"
      fill="#101820"
    />
    <path
      d="M129.875 106.075C129.875 107.281 130.876 108.275 132.091 108.275C133.306 108.275 134.307 107.281 134.307 106.075C134.307 104.869 133.306 103.876 132.091 103.876C130.876 103.876 129.875 104.869 129.875 106.075Z"
      fill="#F16D78"
    />
    <path
      d="M114.401 112.141H115.045C118.154 112.141 120.691 109.622 120.691 106.536V105.259C120.691 102.173 118.154 99.6549 115.045 99.6549H114.401V112.141Z"
      fill="#101820"
    />
    <path
      d="M109.038 152.551L107.823 159.823C127.873 163.157 143.098 179.474 144.885 199.551L145.564 207L152.962 206.362L152.282 198.912C150.21 175.466 132.412 156.417 109.038 152.551Z"
      fill="#101820"
    />
    <path
      d="M155.748 218.375C157.142 217.027 158 215.289 158 213.374C158 211.174 156.856 209.188 155.141 207.734C155.57 207.308 155.856 206.705 155.856 206.066C155.856 204.789 154.819 203.761 153.533 203.761H144.133C142.847 203.761 141.81 204.789 141.81 206.066C141.81 206.74 142.06 207.308 142.525 207.734C140.702 209.152 139.559 211.139 139.559 213.338C139.559 215.218 140.381 216.956 141.774 218.304L144.848 220.255C146.027 220.752 147.35 221 148.779 221C150.173 221 151.495 220.752 152.675 220.291L155.748 218.375Z"
      fill="#F16D78"
    />
    <path
      d="M48.9622 152.551L50.1773 159.822C30.1278 163.157 14.903 179.474 13.1161 199.55L12.437 207L5.03906 206.361L5.7181 198.912C7.79096 175.465 25.589 156.417 48.9622 152.551Z"
      fill="#101820"
    />
    <path
      d="M2.25155 217.699C0.857735 216.351 0 214.613 0 212.697C0 210.498 1.14365 208.511 2.96633 207.057C2.53747 206.631 2.25155 206.028 2.25155 205.39C2.25155 204.113 3.28798 203.084 4.57458 203.084H13.8667C15.1533 203.084 16.1897 204.113 16.1897 205.39C16.1897 206.064 15.9396 206.631 15.475 207.057C17.2976 208.476 18.4413 210.462 18.4413 212.662C18.4413 214.542 17.6193 216.28 16.2255 217.628L13.1519 219.579C11.9725 220.075 10.6502 220.323 9.22065 220.323C7.82683 220.323 6.50449 220.075 5.3251 219.614L2.25155 217.699Z"
      fill="#F16D78"
    />
    <path
      d="M94.5653 135.481C94.5653 134.63 93.8505 133.92 92.9928 133.92C93.8505 133.92 94.5653 133.211 94.5653 132.359C94.5653 131.508 93.8505 130.799 92.9928 130.799H64.4016C63.5439 130.799 62.8291 131.508 62.8291 132.359C62.8291 133.211 63.5439 133.92 64.4016 133.92C63.5439 133.92 62.8291 134.63 62.8291 135.481C62.8291 136.332 63.5439 137.042 64.4016 137.042C63.5439 137.042 62.8291 137.751 62.8291 138.602C62.8291 139.454 63.5439 140.163 64.4016 140.163H92.9928C93.8505 140.163 94.5653 139.454 94.5653 138.602C94.5653 137.751 93.8505 137.042 92.9928 137.042C93.8505 137.042 94.5653 136.332 94.5653 135.481Z"
      fill="#101820"
    />
    <path
      d="M63.1248 88.172L55.2676 47.7546L68.6828 86.681L63.1248 88.172Z"
      fill="#101820"
    />
    <path
      d="M88.924 86.6765L102.339 47.7501L94.5164 88.1767L88.924 86.6765Z"
      fill="#101820"
    />
    <path
      d="M104.965 92.0285H52.4287V86.6723C52.4287 81.2806 56.8246 76.9176 62.2569 76.9176H95.1368C100.569 76.9176 104.965 81.2806 104.965 86.6723V92.0285Z"
      fill="#101820"
    />
    <path
      d="M43.2087 170.03H41.9936C36.2039 170.03 31.4863 165.348 31.4863 159.602V157.225C31.4863 151.479 36.2039 146.796 41.9936 146.796H43.2087V170.03Z"
      fill="#101820"
    />
    <path
      d="M114.401 170.03H115.616C121.406 170.03 126.124 165.348 126.124 159.602V157.225C126.124 151.479 121.406 146.796 115.616 146.796H114.401V170.03Z"
      fill="#101820"
    />
    <path
      d="M104.862 131.967H52.8941C47.4086 131.967 42.9336 127.598 42.9336 122.163V91.7908C42.9336 86.3913 47.3725 81.9865 52.8941 81.9865H104.862C110.347 81.9865 114.822 86.3558 114.822 91.7908V122.163C114.822 127.562 110.347 131.967 104.862 131.967Z"
      fill="#F16D78"
    />
    <path
      d="M78.9435 108.179C86.108 108.179 89.7081 111.648 91.4546 114.521C92.1319 115.607 92.5596 116.693 92.8804 117.639C93.3794 119.25 92.2031 120.862 90.5279 120.967C89.3516 121.037 88.2823 120.266 87.9258 119.145C87.712 118.479 87.3912 117.744 86.9278 117.008C85.3595 114.521 82.6861 113.294 78.9792 113.294C75.2722 113.294 72.5988 114.556 71.0305 117.008C70.5671 117.744 70.2463 118.514 70.0325 119.145C69.676 120.266 68.6067 121.002 67.4304 120.967C65.7195 120.897 64.5433 119.25 65.0779 117.639C65.3631 116.693 65.8264 115.607 66.5037 114.521C68.179 111.648 71.8147 108.179 78.9435 108.179Z"
      fill="white"
    />
    <path
      d="M78.6083 110.448C82.0006 110.448 84.8156 111.299 86.9448 112.966C88.3884 114.101 89.5071 115.555 90.265 117.294C90.4094 117.613 90.265 117.968 89.9041 118.109C89.5793 118.216 89.2545 118.074 89.1102 117.79C87.8109 114.882 84.9599 111.654 78.5722 111.654C72.1844 111.654 69.3334 114.882 68.0342 117.79C67.8899 118.074 67.5651 118.216 67.2403 118.109C66.9155 118.003 66.735 117.613 66.8794 117.294C67.6733 115.52 68.7921 114.066 70.1995 112.966C72.4371 111.299 75.2159 110.448 78.6083 110.448Z"
      fill="#101820"
    />
    <path
      d="M106.002 126.294H51.5719C46.9616 126.294 43.209 122.569 43.209 117.994V123.456C43.209 128.032 46.9616 131.757 51.5719 131.757H106.002C110.613 131.757 114.365 128.032 114.365 123.456V117.994C114.401 122.569 110.648 126.294 106.002 126.294Z"
      fill="#C65A63"
    />
    <path
      d="M105.824 201.99H51.7506C47.0331 201.99 43.209 198.195 43.209 193.513V147.258C43.209 142.575 47.0331 138.78 51.7506 138.78H105.824C110.541 138.78 114.365 142.575 114.365 147.258V193.513C114.401 198.195 110.577 201.99 105.824 201.99Z"
      fill="#F16D78"
    />
    <path
      d="M106.002 196.528H51.5719C46.9616 196.528 43.209 192.803 43.209 188.227V193.655C43.209 198.23 46.9616 201.955 51.5719 201.955H106.002C110.613 201.955 114.365 198.23 114.365 193.655V188.192C114.401 192.803 110.648 196.528 106.002 196.528Z"
      fill="#C65A63"
    />
    <path
      opacity="0.3"
      d="M75.4788 185.205H53.5709C50.9977 185.205 48.9248 183.147 48.9248 180.593V155.621C48.9248 153.067 50.9977 151.01 53.5709 151.01H75.4788C78.052 151.01 80.1249 153.067 80.1249 155.621V180.593C80.1249 183.147 78.052 185.205 75.4788 185.205Z"
      fill="#101820"
    />
    <path
      opacity="0.3"
      d="M105.787 185.46H88.275C85.7018 185.46 83.6289 183.403 83.6289 180.849V155.877C83.6289 153.323 85.7018 151.266 88.275 151.266H105.787C108.36 151.266 110.433 153.323 110.433 155.877V180.849C110.433 183.403 108.36 185.46 105.787 185.46Z"
      fill="#101820"
    />
    <path
      d="M97.032 155.522C90.8492 155.522 85.8457 160.63 85.8457 166.944H108.218C108.218 160.63 103.215 155.522 97.032 155.522Z"
      fill="#101820"
    />
    <path
      d="M97.0318 157.119C92.3143 157.119 88.4902 160.772 88.4902 165.277H105.609C105.573 160.772 101.749 157.119 97.0318 157.119Z"
      fill="white"
    />
    <path
      d="M90.3124 177.302C90.3124 178.295 89.4904 179.111 88.4897 179.111C87.489 179.111 86.667 178.295 86.667 177.302C86.667 176.309 87.489 175.493 88.4897 175.493C89.4904 175.493 90.3124 176.309 90.3124 177.302Z"
      fill="#101820"
    />
    <path
      d="M108.111 177.302C108.111 178.295 107.289 179.111 106.289 179.111C105.288 179.111 104.466 178.295 104.466 177.302C104.466 176.309 105.288 175.493 106.289 175.493C107.289 175.493 108.111 176.309 108.111 177.302Z"
      fill="#101820"
    />
    <path
      opacity="0.3"
      d="M96.5303 164.249L99.8183 158.289L97.9956 164.426L96.5303 164.249Z"
      fill="#101820"
    />
    <path
      d="M98.6757 165.278C98.6757 165.242 98.6757 165.207 98.6757 165.171C98.6757 164.249 97.9252 163.539 97.0317 163.539C96.1025 163.539 95.3877 164.284 95.3877 165.171C95.3877 165.207 95.3877 165.242 95.3877 165.278H98.6757Z"
      fill="#F16D78"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.954 168.127C65.19 168.346 65.2672 168.675 65.1511 168.966C64.9365 169.505 64.1996 169.655 63.7659 169.249L62.0358 167.625L58.367 171.067C58.0342 171.38 57.4946 171.38 57.1618 171.067C56.8275 170.754 56.8292 170.245 57.1653 169.933L60.8996 166.471C60.116 165.654 59.4474 164.771 58.9005 163.843C58.6224 163.371 59.003 162.816 59.5778 162.816C59.8753 162.816 60.146 162.973 60.2912 163.217C60.7719 164.023 61.3534 164.789 62.0358 165.501C63.2659 164.219 64.1672 162.752 64.7438 161.205H57.8344C57.3649 161.205 56.9844 160.848 56.9844 160.408C56.9844 159.967 57.3649 159.61 57.8344 159.61H61.1815V158.809C61.1815 158.366 61.564 158.007 62.0358 158.007C62.5076 158.007 62.89 158.366 62.89 158.809V159.61H68.61C69.0818 159.61 69.4643 159.971 69.4643 160.414C69.4643 160.856 69.0818 161.217 68.61 161.217H66.367C65.7433 163.109 64.684 164.896 63.1976 166.451L63.172 166.475L64.954 168.127ZM68.3299 166.87C68.7274 166.87 69.0832 167.109 69.2227 167.469L72.8293 176.781C73.0481 177.346 72.6166 177.949 71.9933 177.949C71.6211 177.949 71.288 177.725 71.1573 177.388L70.3018 175.179H65.7726L64.9171 177.388C64.7864 177.725 64.4533 177.949 64.0811 177.949C63.4578 177.949 63.0263 177.346 63.2451 176.781L66.8517 167.469C66.9912 167.109 67.347 166.87 67.7445 166.87H68.3299ZM66.4877 173.333L68.0372 169.33L69.5867 173.333H66.4877Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.7461 102.533C65.8701 103.348 66.6376 103.91 67.4603 103.787C68.2829 103.665 68.8493 102.904 68.7252 102.088C68.6948 101.888 68.6805 101.674 68.6638 101.388L68.6612 101.343C68.6461 101.085 68.6273 100.761 68.5815 100.432C68.5789 100.414 68.5763 100.395 68.5736 100.377C69.7463 99.6714 70.7342 98.7072 71.5673 97.8942L71.5673 97.8942C71.7264 97.7389 71.8798 97.5892 72.0278 97.4478C72.6268 96.8755 72.6444 95.93 72.067 95.336C71.4897 94.7419 70.536 94.7242 69.937 95.2964C69.7447 95.4801 69.5613 95.6582 69.3841 95.8302C68.7732 96.4232 68.2361 96.9446 67.6625 97.3796C67.5258 97.0854 67.3781 96.7977 67.2212 96.5167L67.1611 96.4086C66.8344 95.8213 66.3337 94.9209 65.6885 94.257C65.1111 93.6629 64.1575 93.6452 63.5585 94.2174C62.9594 94.7897 62.9419 95.7352 63.5193 96.3293C63.8719 96.6921 64.1986 97.2696 64.5852 97.9624C64.7119 98.1894 64.8287 98.4177 64.9349 98.6476C64.588 98.7261 64.2297 98.7824 63.8587 98.8185C63.7757 98.8266 63.6941 98.8369 63.6272 98.8456L63.5585 98.8546L63.5584 98.8546C63.516 98.8601 63.4788 98.865 63.4372 98.8702C63.3694 98.8785 63.3153 98.8842 63.2712 98.8876C62.6993 98.5349 61.9382 98.5966 61.4312 99.0809C60.8322 99.6532 60.8146 100.599 61.392 101.193C62.0178 101.837 62.8298 101.883 63.2212 101.879C63.5278 101.875 63.9917 101.813 64.1204 101.796C64.1391 101.793 64.1507 101.792 64.1538 101.791C64.6609 101.742 65.1618 101.663 65.6553 101.549C65.6555 101.552 65.6558 101.556 65.656 101.56L65.656 101.561C65.672 101.833 65.6922 102.178 65.7461 102.533Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.8809 102.533C92.7569 103.348 91.9894 103.91 91.1668 103.787C90.3441 103.665 89.7777 102.904 89.9018 102.088C89.9322 101.888 89.9465 101.674 89.9633 101.388L89.9659 101.343L89.9659 101.343C89.9809 101.085 89.9997 100.761 90.0455 100.432C90.0481 100.414 90.0507 100.396 90.0533 100.378C88.8802 99.6719 87.892 98.7074 87.0587 97.8942L87.0587 97.8941C86.8996 97.7389 86.7462 97.5892 86.5982 97.4478C85.9992 96.8755 85.9816 95.93 86.559 95.336C87.1364 94.7419 88.09 94.7242 88.689 95.2964C88.8813 95.4801 89.0648 95.6582 89.2419 95.8302C89.8531 96.4235 90.3904 96.9451 90.9643 97.3801C91.101 97.0857 91.2488 96.7979 91.4058 96.5167L91.4659 96.4086C91.7926 95.8213 92.2933 94.9209 92.9385 94.257C93.5159 93.6629 94.4695 93.6452 95.0685 94.2174C95.6676 94.7897 95.6851 95.7352 95.1077 96.3293C94.7551 96.6921 94.4284 97.2696 94.0418 97.9624C93.915 98.1894 93.7982 98.4178 93.692 98.6478C94.0386 98.7262 94.3966 98.7825 94.7673 98.8185C94.8504 98.8266 94.9319 98.8369 94.9988 98.8456L95.0675 98.8546L95.0676 98.8546C95.11 98.8601 95.1473 98.865 95.1888 98.8702C95.2567 98.8785 95.3107 98.8842 95.3548 98.8876C95.9267 98.5349 96.6878 98.5966 97.1948 99.0809C97.7938 99.6532 97.8114 100.599 97.234 101.193C96.6083 101.837 95.7962 101.883 95.4048 101.879C95.0982 101.875 94.6344 101.813 94.5056 101.796C94.4869 101.793 94.4753 101.792 94.4722 101.791C93.9655 101.742 93.4649 101.663 92.9717 101.549C92.9715 101.552 92.9712 101.556 92.971 101.56L92.971 101.561V101.561C92.955 101.833 92.9348 102.178 92.8809 102.533Z"
      fill="white"
    />
    <path
      d="M57.0479 97.0664C59.183 94.0423 62.0921 91.5012 65.5822 90.1792C66.082 89.9899 68.7951 89.0725 69.1548 89.7857"
      stroke="#101820"
      stroke-linecap="round"
    />
    <path
      d="M101.762 97.6704C99.6266 94.6463 96.7175 92.1052 93.2273 90.7832C92.7275 90.5939 90.0145 89.6765 89.6548 90.3897"
      stroke="#101820"
      stroke-linecap="round"
    />
    <g opacity="0.5" clip-path="url(#clip0_0_1)">
      <path
        d="M169.676 5.41275L162.372 18.594L166.801 26.0828C158.539 30.673 156.426 33.5467 148.242 38.0932L148.183 38.0509L155.499 24.8473L150.948 16.1259C159.205 11.5386 161.429 9.89237 169.611 5.34679L169.676 5.41275Z"
        fill="black"
      />
    </g>
    <path
      d="M159.743 42.8668L149.069 62.1316L155.43 73.0147C143.428 79.7649 140.347 83.9686 128.461 90.6545L128.375 90.5934L139.068 71.2959L132.54 58.6172C144.533 51.8713 147.765 49.4557 159.651 42.7711L159.743 42.8668Z"
      fill="black"
    />
    <g opacity="0.2" clip-path="url(#clip1_0_1)">
      <path
        d="M179.367 31.5911L174.686 40.0406L177.45 44.7997C172.203 47.7697 170.853 49.6143 165.657 52.5561L165.62 52.5294L170.309 44.0656L167.474 38.5203C172.717 35.5521 174.131 34.4905 179.327 31.5493L179.367 31.5911Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="27"
          height="39"
          fill="white"
          transform="translate(159.901) rotate(28.9894)"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="17"
          height="25"
          fill="white"
          transform="translate(171.116 27) rotate(28.9894)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ErrorBot;
