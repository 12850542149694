const SuccessBot = () => (
  <svg
    width="160"
    height="156"
    viewBox="0 0 160 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.072 61.2137L134.3 59.6523L112.072 58.1265V61.2137Z"
      fill="white"
    />
    <path
      d="M130.076 59.6524C130.076 60.8589 131.078 61.8525 132.295 61.8525C133.512 61.8525 134.515 60.8589 134.515 59.6524C134.515 58.4459 133.512 57.4523 132.295 57.4523C131.078 57.4523 130.076 58.4459 130.076 59.6524Z"
      fill="#F16D78"
    />
    <path
      d="M114.578 65.7204H115.222C118.337 65.7204 120.878 63.2009 120.878 60.1137V58.8362C120.878 55.749 118.337 53.2296 115.222 53.2296H114.578V65.7204Z"
      fill="#101820"
    />
    <path
      d="M45.8161 61.2137L23.5879 59.6523L45.8161 58.1265V61.2137Z"
      fill="#101820"
    />
    <path
      d="M27.8115 59.6524C27.8115 60.8589 26.8093 61.8525 25.5923 61.8525C24.3753 61.8525 23.373 60.8589 23.373 59.6524C23.373 58.4459 24.3753 57.4523 25.5923 57.4523C26.8093 57.4523 27.8115 58.4459 27.8115 59.6524Z"
      fill="#F16D78"
    />
    <path
      d="M43.3095 65.7204H42.6652C39.5512 65.7204 37.0098 63.2009 37.0098 60.1137V58.8362C37.0098 55.749 39.5512 53.2296 42.6652 53.2296H43.3095V65.7204Z"
      fill="#101820"
    />
    <path
      d="M112.072 61.2137L134.3 59.6523L112.072 58.1265V61.2137Z"
      fill="#101820"
    />
    <path
      d="M130.076 59.6524C130.076 60.8589 131.078 61.8525 132.295 61.8525C133.512 61.8525 134.515 60.8589 134.515 59.6524C134.515 58.4459 133.512 57.4523 132.295 57.4523C131.078 57.4523 130.076 58.4459 130.076 59.6524Z"
      fill="#F16D78"
    />
    <path
      d="M114.578 65.7204H115.222C118.337 65.7204 120.878 63.2009 120.878 60.1137V58.8362C120.878 55.749 118.337 53.2296 115.222 53.2296H114.578V65.7204Z"
      fill="#101820"
    />
    <path
      d="M110.961 119.587L109.744 112.313C129.825 108.977 145.073 92.6539 146.863 72.5692L147.543 65.1172L154.952 65.756L154.272 73.2079C152.196 96.6637 134.371 115.719 110.961 119.587Z"
      fill="#101820"
    />
    <path
      d="M159.999 60.1492C159.999 58.233 159.14 56.4942 157.744 55.1458V48.4036C157.744 47.5519 157.064 46.8777 156.205 46.8777C155.346 46.8777 154.666 47.5519 154.666 48.4036V53.2296C153.485 52.7682 152.16 52.5199 150.764 52.5199C149.332 52.5199 148.008 52.7683 146.827 53.265V48.439C146.827 47.5874 146.147 46.9132 145.288 46.9132C144.429 46.9132 143.749 47.5874 143.749 48.439V55.2167C142.353 56.5652 141.529 58.304 141.529 60.1847C141.529 62.3848 142.675 64.372 144.5 65.7914C144.035 66.2172 143.784 66.785 143.784 67.4592C143.784 68.7367 144.822 69.7657 146.111 69.7657H155.525C156.813 69.7657 157.851 68.7367 157.851 67.4592C157.851 66.8204 157.565 66.2172 157.136 65.7914C158.854 64.3365 159.999 62.3493 159.999 60.1492Z"
      fill="#F16D78"
    />
    <path
      d="M49.0379 119.587L50.2549 112.313C30.1744 108.977 14.9261 92.6539 13.1364 72.5692L12.4563 65.1172L5.04688 65.756L5.72696 73.2079C7.80303 96.6637 25.6285 115.719 49.0379 119.587Z"
      fill="#101820"
    />
    <path
      d="M0 60.1492C0 58.233 0.85906 56.4942 2.25503 55.1458V48.4036C2.25503 47.5519 2.93512 46.8777 3.79418 46.8777C4.65324 46.8777 5.33333 47.5519 5.33333 48.4036V53.2296C6.51454 52.7682 7.83893 52.5199 9.2349 52.5199C10.6667 52.5199 11.9911 52.7683 13.1723 53.265V48.439C13.1723 47.5874 13.8523 46.9132 14.7114 46.9132C15.5705 46.9132 16.2506 47.5874 16.2506 48.439V55.2167C17.6465 56.5652 18.4698 58.304 18.4698 60.1847C18.4698 62.3848 17.3244 64.372 15.4989 65.7914C15.9642 66.2172 16.2148 66.785 16.2148 67.4592C16.2148 68.7367 15.1767 69.7657 13.8881 69.7657H4.58166C3.29306 69.7657 2.25503 68.7367 2.25503 67.4592C2.25503 66.8204 2.54139 66.2172 2.97092 65.7914C1.14541 64.3365 0 62.3493 0 60.1492Z"
      fill="#F16D78"
    />
    <path
      d="M94.711 89.0697C94.711 88.2181 93.9951 87.5084 93.1361 87.5084C93.9951 87.5084 94.711 86.7987 94.711 85.947C94.711 85.0954 93.9951 84.3857 93.1361 84.3857H64.5007C63.6417 84.3857 62.9258 85.0954 62.9258 85.947C62.9258 86.7987 63.6417 87.5084 64.5007 87.5084C63.6417 87.5084 62.9258 88.2181 62.9258 89.0697C62.9258 89.9214 63.6417 90.6311 64.5007 90.6311C63.6417 90.6311 62.9258 91.3408 62.9258 92.1924C62.9258 93.0441 63.6417 93.7538 64.5007 93.7538H93.1361C93.9951 93.7538 94.711 93.0441 94.711 92.1924C94.711 91.3408 93.9951 90.6311 93.1361 90.6311C93.9951 90.6311 94.711 89.9214 94.711 89.0697Z"
      fill="#101820"
    />
    <path
      d="M57.8066 41.6968L60.6702 0.604797L63.5695 41.6968H57.8066Z"
      fill="#101820"
    />
    <path
      d="M94.2812 41.6968L97.1806 0.604797L100.08 41.6968H94.2812Z"
      fill="#101820"
    />
    <path
      d="M105.127 45.6002H52.5098V40.2419C52.5098 34.8482 56.9125 30.4835 62.3532 30.4835H95.2838C100.725 30.4835 105.127 34.8482 105.127 40.2419V45.6002Z"
      fill="#101820"
    />
    <path
      d="M43.2756 123.632H42.0586C36.26 123.632 31.5352 118.948 31.5352 113.2V110.822C31.5352 105.074 36.26 100.39 42.0586 100.39H43.2756V123.632Z"
      fill="#101820"
    />
    <path
      d="M114.578 123.632H115.795C121.594 123.632 126.319 118.948 126.319 113.2V110.822C126.319 105.074 121.594 100.39 115.795 100.39H114.578V123.632Z"
      fill="#101820"
    />
    <path
      d="M104.698 85.3438H53.1546C47.7139 85.3438 43.2754 80.9791 43.2754 75.5499V45.2099C43.2754 39.8161 47.6781 35.416 53.1546 35.416H104.698C110.139 35.416 114.577 39.7807 114.577 45.2099V75.5499C114.577 80.9436 110.139 85.3438 104.698 85.3438Z"
      fill="#F16D78"
    />
    <path
      d="M62.3174 54.9329C62.3174 58.5878 59.3465 61.5331 55.6597 61.5331C51.9729 61.5331 49.002 58.5878 49.002 54.9329C49.002 51.2779 51.9729 48.3326 55.6597 48.3326C59.3465 48.3326 62.3174 51.2779 62.3174 54.9329Z"
      fill="white"
    />
    <path
      d="M108.851 54.9329C108.851 58.5878 105.88 61.5331 102.193 61.5331C98.5061 61.5331 95.5352 58.5878 95.5352 54.9329C95.5352 51.2779 98.5061 48.3326 102.193 48.3326C105.88 48.3326 108.851 51.2779 108.851 54.9329Z"
      fill="white"
    />
    <path
      d="M78.9262 71.5755C71.7315 71.5755 68.1163 68.0624 66.3624 65.1526C65.6823 64.0526 65.2528 62.9525 64.9306 61.9944C64.4295 60.3621 65.6107 58.7298 67.2931 58.6233C68.4743 58.5523 69.5481 59.333 69.906 60.4685C70.1208 61.1428 70.443 61.888 70.9083 62.6332C72.4832 65.1526 75.1678 66.3946 78.8904 66.3946C82.613 66.3946 85.2975 65.1171 86.8725 62.6332C87.3378 61.888 87.66 61.1073 87.8747 60.4685C88.2327 59.333 89.3065 58.5878 90.4877 58.6233C92.2058 58.6943 93.387 60.3621 92.8501 61.9944C92.5637 62.9525 92.0984 64.0526 91.4183 65.1526C89.736 68.0624 86.085 71.5755 78.9262 71.5755Z"
      fill="white"
    />
    <path
      d="M78.9269 69.5883C75.5623 69.5883 72.7703 68.7367 70.6585 67.0688C69.2267 65.9333 68.1171 64.4784 67.3654 62.7396C67.2222 62.4203 67.3654 62.0654 67.7234 61.9235C68.0455 61.817 68.3676 61.959 68.5108 62.2428C69.7994 65.1526 72.6272 68.3818 78.9627 68.3818C85.2983 68.3818 88.126 65.1526 89.4146 62.2428C89.5578 61.959 89.88 61.817 90.2021 61.9235C90.5242 62.0299 90.7032 62.4203 90.56 62.7396C89.7726 64.5139 88.663 65.9688 87.267 67.0688C85.0477 68.7367 82.2916 69.5883 78.9269 69.5883Z"
      fill="#101820"
    />
    <path
      d="M60.4568 54.9328C60.4568 57.5587 58.3091 59.6524 55.6962 59.6524C53.0474 59.6524 50.9355 57.5232 50.9355 54.9328C50.9355 52.3069 53.0832 50.2133 55.6962 50.2133C58.3091 50.1778 60.4568 52.3069 60.4568 54.9328Z"
      fill="#101820"
    />
    <path
      d="M106.953 54.9328C106.953 57.5587 104.805 59.6524 102.192 59.6524C99.5435 59.6524 97.4316 57.5232 97.4316 54.9328C97.4316 52.3069 99.5793 50.2133 102.192 50.2133C104.805 50.1778 106.953 52.3069 106.953 54.9328Z"
      fill="#101820"
    />
    <path
      d="M104.805 51.7392C105.843 52.6263 106.094 53.9748 105.378 54.8264C104.662 55.6426 103.266 55.6071 102.228 54.7554C101.19 53.8683 100.939 52.5199 101.655 51.6682C102.371 50.8521 103.767 50.8875 104.805 51.7392Z"
      fill="#E2E3E4"
    />
    <path
      d="M58.4869 51.7392C59.5249 52.6263 59.7755 53.9748 59.0596 54.8264C58.3437 55.6426 56.9477 55.6071 55.9097 54.7554C54.8717 53.8683 54.6211 52.5199 55.337 51.6682C56.0529 50.8521 57.4488 50.8875 58.4869 51.7392Z"
      fill="#E2E3E4"
    />
    <path
      d="M106.166 79.879H51.6512C47.0338 79.879 43.2754 76.153 43.2754 71.5754V77.0402C43.2754 81.6178 47.0338 85.3437 51.6512 85.3437H106.166C110.783 85.3437 114.542 81.6178 114.542 77.0402V71.5754C114.577 76.153 110.819 79.879 106.166 79.879Z"
      fill="#C75962"
    />
    <path
      d="M105.987 155.605H51.8302C47.1054 155.605 43.2754 151.808 43.2754 147.124V100.851C43.2754 96.1669 47.1054 92.3699 51.8302 92.3699H105.987C110.712 92.3699 114.542 96.1669 114.542 100.851V147.124C114.577 151.808 110.747 155.605 105.987 155.605Z"
      fill="#F16D78"
    />
    <path
      d="M106.166 150.14H51.6512C47.0338 150.14 43.2754 146.414 43.2754 141.836V147.266C43.2754 151.843 47.0338 155.569 51.6512 155.569H106.166C110.783 155.569 114.542 151.843 114.542 147.266V141.801C114.577 146.414 110.819 150.14 106.166 150.14Z"
      fill="#C75962"
    />
    <path
      opacity="0.3"
      d="M75.5951 139.208H53.6532C51.0761 139.208 49 137.15 49 134.595V109.613C49 107.058 51.0761 105 53.6532 105H75.5951C78.1723 105 80.2483 107.058 80.2483 109.613V134.595C80.2483 137.15 78.1723 139.208 75.5951 139.208Z"
      fill="#101820"
    />
    <path
      opacity="0.3"
      d="M105.95 139.069H88.4111C85.8339 139.069 83.7578 137.01 83.7578 134.455V109.474C83.7578 106.919 85.8339 104.861 88.4111 104.861H105.95C108.527 104.861 110.603 106.919 110.603 109.474V134.455C110.603 137.01 108.527 139.069 105.95 139.069Z"
      fill="#101820"
    />
    <path
      d="M97.1821 109.119C90.9897 109.119 85.9785 114.229 85.9785 120.545H108.386C108.386 114.229 103.374 109.119 97.1821 109.119Z"
      fill="#101820"
    />
    <path
      d="M97.1818 110.716C92.4569 110.716 88.627 114.371 88.627 118.877H105.772C105.737 114.371 101.907 110.716 97.1818 110.716Z"
      fill="white"
    />
    <path
      d="M90.4518 130.907C90.4518 131.901 89.6285 132.717 88.6263 132.717C87.6241 132.717 86.8008 131.901 86.8008 130.907C86.8008 129.913 87.6241 129.097 88.6263 129.097C89.6285 129.097 90.4518 129.913 90.4518 130.907Z"
      fill="#101820"
    />
    <path
      d="M108.278 130.907C108.278 131.901 107.455 132.717 106.452 132.717C105.45 132.717 104.627 131.901 104.627 130.907C104.627 129.913 105.45 129.097 106.452 129.097C107.455 129.097 108.278 129.913 108.278 130.907Z"
      fill="#101820"
    />
    <path
      opacity="0.3"
      d="M96.6797 117.848L99.9728 111.887L98.1473 118.026L96.6797 117.848Z"
      fill="#101820"
    />
    <path
      d="M98.8282 118.877C98.8282 118.842 98.8282 118.806 98.8282 118.771C98.8282 117.848 98.0765 117.139 97.1817 117.139C96.251 117.139 95.5352 117.884 95.5352 118.771C95.5352 118.806 95.5352 118.842 95.5352 118.877H98.8282Z"
      fill="#F16D78"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0543 122.124C65.2907 122.343 65.3679 122.671 65.2517 122.963C65.0367 123.502 64.2987 123.653 63.8644 123.246L62.1315 121.622L58.4571 125.065C58.1238 125.378 57.5833 125.378 57.25 125.065C56.9153 124.752 56.9169 124.243 57.2536 123.931L60.9936 120.467C60.2088 119.649 59.5391 118.767 58.9915 117.838C58.7129 117.366 59.0941 116.811 59.6698 116.811C59.9677 116.811 60.2388 116.968 60.3843 117.212C60.8657 118.018 61.4481 118.784 62.1315 119.497C63.3636 118.214 64.2663 116.747 64.8438 115.199H57.9237C57.4535 115.199 57.0723 114.842 57.0723 114.401C57.0723 113.961 57.4535 113.604 57.9237 113.604H61.2759V112.802C61.2759 112.359 61.659 112 62.1315 112C62.6041 112 62.9871 112.359 62.9871 112.802V113.604H68.7159C69.1885 113.604 69.5715 113.965 69.5715 114.408C69.5715 114.85 69.1885 115.211 68.7159 115.211H66.4694C65.8448 117.104 64.7839 118.892 63.2952 120.447L63.2695 120.471L65.0543 122.124ZM68.4355 120.866C68.8335 120.866 69.1899 121.105 69.3297 121.465L72.9418 130.781C73.1609 131.346 72.7288 131.949 72.1045 131.949C71.7318 131.949 71.3981 131.725 71.2673 131.388L70.4104 129.178H65.8742L65.0173 131.388C64.8865 131.725 64.5528 131.949 64.1801 131.949C63.5558 131.949 63.1237 131.346 63.3428 130.781L66.955 121.465C67.0947 121.105 67.4511 120.866 67.8491 120.866H68.4355ZM66.5904 127.331L68.1423 123.327L69.6942 127.331H66.5904Z"
      fill="white"
    />
  </svg>
);

export default SuccessBot;
