import React, { useEffect, useRef } from "react";

import lottie from "lottie-web";
import successAnime from "@/assets/animations/success-animation.json";

export default function SuccessAnimation() {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: successAnime,
    });
    return () => lottie.stop();
  }, []);
  return <div style={{ height: 250, width: 150 }} ref={anime}></div>;
}
